<template>
  <div class="details">
    <div class="details_container">
      <!-- <h5 class="title">DOTA2</h5> -->
      <div class="details_case">
        <div class="details_img_arrow"><img src="../assets/picture/left.png"
               alt=""></div>
        <div class="details_img_box">
          <div class='details_back'><img src="../assets/picture/background.png"
                 alt=""></div>
          <div class="details_main"><img src="https://i.postimg.cc/7Z26Dk23/we-10-64.png"
                 alt=""></div>
          <div class="details_move"><img :src="Item.prospect"
                 alt=""></div>
        </div>
        <div class="details_img_arrow"><img src="../assets/picture/right.png"
               alt=""></div>
      </div>

      <div class="details_count_box">
        <div class="details_count_title">{{Item.name.en_US}}</div>
        <div class="details_count"
             :class="[count==i?'count_light':'']"
             v-for='i in 5'
             :key="i"
             @click='getCount(i)'>{{i}}</div>
      </div>
      <div class="details_btn"
           @click="checkOut">
        <svg class="MuiSvgIcon-root coin_SvgIcon__icon"
             focusable="false"
             viewBox="0 0 1024 1024"
             aria-hidden="true"
             width="1em"
             height="1em"
             style="font-size: 14px;">
          <path d="M122.688 317.312c14.592-29.44 32.512-57.088 53.248-82.24l-56-56a506.624 506.624 0 00-69.568 106.432l72.32 31.808zm-43.52 165.376c2.24-40.768 10.24-79.936 23.04-116.8l-72.32-31.936A504.064 504.064 0 00.192 482.688h79.04zm132.672-286.08a428.224 428.224 0 0198.816-70.08l-28.672-73.92A507.2 507.2 0 00156.032 140.8l55.808 55.872zM96.448 629.632a425.728 425.728 0 01-17.024-94.336H.384c2.368 42.624 9.984 83.84 22.272 123.008l73.792-28.672zM513.344 79.616c42.88.768 84.288 7.872 123.264 20.416l31.936-72.576A505.088 505.088 0 00513.28.64v78.912zm360.384 79.872L817.92 215.36a427.968 427.968 0 0172.448 105.664l73.728-28.672a507.008 507.008 0 00-90.368-132.864zm-188.096-40.256a427.392 427.392 0 0194.08 59.904l56-56a506.496 506.496 0 00-118.272-76.16l-31.808 72.32zm-326.72-13.824a424.64 424.64 0 01101.76-23.552V2.624a503.296 503.296 0 00-130.304 29.184l28.544 73.6zM178.048 780.8a427.2 427.2 0 01-63.36-101.76l-73.6 28.608a506.24 506.24 0 0080.96 129.088l56-56zm753.728-245.504a424.96 424.96 0 01-27.904 125.952l72.32 31.808c19.52-49.28 31.552-102.336 34.624-157.76H931.84zm-49.92 173.76a428.416 428.416 0 01-66.176 91.328l55.808 55.872a508.183 508.183 0 0082.752-115.328l-72.384-31.872zm28.48-339.264c12.032 35.712 19.52 73.6 21.76 112.896h78.976a504.256 504.256 0 00-27.136-141.568l-73.6 28.672zm-449.6 561.664A424.32 424.32 0 01341.44 901.12l-31.808 72.256c47.168 19.84 97.92 32.64 151.04 37.312v-79.232zm316.416-95.104a424.214 424.214 0 01-108.736 65.28l28.608 73.6a505.92 505.92 0 00136.128-82.88l-56-56zm-482.944 41.536a428.416 428.416 0 01-80-58.88L158.4 874.816a507.52 507.52 0 00103.808 75.648l32-72.576zm324.352 40.768a427.072 427.072 0 01-105.216 15.04v78.912a505.792 505.792 0 00133.952-20.032l-28.736-73.92z"></path>
          <path d="M505.6 110.528a396.16 396.16 0 100 792.32 396.16 396.16 0 000-792.32zM768 612.992c0 15.68-5.056 32.448-15.104 50.304-10.112 17.856-25.472 34.816-46.144 50.944-20.672 16.192-46.72 29.184-78.144 39.04A354.624 354.624 0 01522.368 768c-83.648 0-148.928-22.656-195.904-68.032S256 593.792 256 517.568c0-51.712 10.752-97.344 32.256-137.024a222.72 222.72 0 0193.184-92.032C422.208 266.88 470.848 256 527.424 256c35.008
0 67.2 4.736 96.448 14.272 29.248 9.6 54.08 21.888 74.432 36.928 20.352 15.04 35.84 31.104 46.656 48.192 10.688 17.088 16.064 32.96 16.064 47.744a48.832 48.832 0 01-18.048 38.272 64.768 64.768 0 01-43.968 15.68 46.592 46.592 0 01-28.032-8.064 131.776 131.776 0 01-25.024-26.048c-16.512-23.36-33.792-40.896-51.84-52.608-18.048-11.648-40.96-17.472-68.736-17.472-40 0-72.256 14.528-96.704 43.52-24.512 29.12-36.736 68.864-36.736
119.296 0 23.68 3.2 45.44 9.408 65.28 6.336 19.84 15.36 36.736 27.328 50.688 11.904 14.016 26.24 24.64 43.136 31.872 16.896 7.168 35.392 10.816 55.552 10.816 27.136 0 50.368-5.824 69.76-17.536 19.328-11.712 36.48-29.504 51.328-53.504 8.256-14.144 17.216-25.216 26.752-33.216a53.12 53.12 0 0135.2-11.968c16.64 0 30.336 5.824 41.216 17.536C762.496 587.328 768 599.808 768 612.992z"></path>
        </svg>
        ${{price}}
        <div class="product_min_btn">OPEN</div>
      </div>

    </div>
  </div>
</template>

<script>
import goods from '../utils/product'
// import qs from 'qs'
export default {
  data () {
    return {
      count: 1,
      id: '',
      appid: '',
      goods: '',
      Item: '',
      price: 0
    }
  },
  methods: {
    getCount (e) {
      this.count = e
      this.price = (Number(this.Item.one_price) * e).toFixed(2)
    },
    getItem () {
      let id = this.id
      let appid = this.appid
      this.goods.map(v => {
        if (v.id == appid) {
          v.cases.map(c => {
            if (c.id == id) {
              this.Item = c
              this.price = c.one_price
            }
          })

        }
      })
    },
    checkOut () {
      let goodsCart = this.Item
      sessionStorage.removeItem('goodsCart')
      let itemArr = []
      goodsCart.count = this.count
      goodsCart.allPrice = this.price
      itemArr.push(goodsCart)
      sessionStorage.setItem('goodsCart', JSON.stringify(itemArr))
      this.$router.push('/checkout')
     
      // let orderNo = ((Math.random() + 1) * 1000000000000000).toFixed(0)
      //  let price = this.Item.price.split("$")[1]
      //  let count = Number(this.count)
      //  let amount = (Number(price*count)).toFixed(2)
      // let amount = this.price

      // const loading = this.$loading({
      //   lock: true,
      //   text: 'Loading',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.7)'
      // });
      // this.$axios({
      //   url: '/pay/payment',
      //   method: 'post',
      //   data: qs.stringify({
      //     agentId: "202207240001",
      //     amount: amount,
      //     orderNo: orderNo,
      //     sign: 'adcd',
      //     notifyUrl: 'www.adcd.com',
      //     returnUrl: 'http://www.hfhczgz.com/#/finish'
      //   }),
      //   headers: {
      //     'Content-type': 'application/x-www-form-urlencoded'
      //   }

      // }).then(res => {
      //   loading.close();
      //   if (res.data.code == 200) {
      //     console.log('res', res)
      //     if (res.data.data.respCode == 10000) {
      //       this.$message({
      //         message: res.data.data.respMessage,
      //         type: 'success'
      //       })
      //     }
      //     if (res.data.data.respCode == 10001) {
      //       this.$message({
      //         message: res.data.data.respMessage,
      //         type: 'error'
      //       })
      //     }
      //     if (res.data.data.respCode == 10002) {
      //       this.$message({
      //         message: res.data.data.respMessage,
      //         type: 'success'
      //       })
      //       if (res.data.data.payUrl) {
      //         // window.open(res.data.data.payUrl)
      //         window.location.href = res.data.data.payUrl
      //       }
      //     }
      //   } else {
      //     this.$message({
      //       message: res.data.message,
      //       type: 'error'
      //     })
      //   }
      // }).catch(e => {
      //   loading.close(e);
      // })
    },
  },
  created () {
    this.id = this.$route.query.id
    this.appid = this.$route.query.appid
    this.goods = goods.data
    this.getItem()
  },

}
</script>

<style lang='scss' scoped>
.details {
  width: 100%;
  .details_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height:500px;
    .details_case {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 30px 0;
      .details_img_box {
        width: 485px;

        position: relative;
        .details_back {
          width: 100%;
          height: 100%;
          z-index: 1;
          > img {
            width: 100%;
          }
        }
        .details_main {
          position: absolute;
          // width:280px;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: 0;
          z-index: 2;
          > img {
            // width:280px;
            margin-top: -5%;
            height: 120%;
          }
        }

        .details_move {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: 0;
          height: 100%;
          z-index: 2;
          > img {
            height: 70%;
            margin-top: 7%;
          }
        }
        &:hover {
          .details_move {
            animation: move_up 2s infinite;
            animation-direction: normal; /*轮流反向播放动画。*/
            animation-timing-function: ease; /*动画的速度曲线*/
            animation-iteration-count: infinite;
            animation-fill-mode: forwards;
          }
        }
      }
      @keyframes move_up {
        0% {
          top: 0%;
          // transform: rotate(0);
        }
        50% {
          top: -20px;
          // transform: rotate(-10deg);
        }
        100% {
          top: 0%;
        }
      }
    }
    .details_price {
      color: #75dc9e;
      margin: 8px auto;
      font-size: 20px;
    }
    .details_btn {
      height: 45px;
      // background: #d30400;

      width: 200px;
      color: #e9b101;
      font-size: 14px;
      border-radius: 30px;
      margin-top: 15px;
      line-height: 45px;
      font-weight: bold;
      cursor: pointer;
      text-align: left;
      border: 1px solid #e9b101;
      position: relative;
      padding: 0 0 0 20px;
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      font-size: 20px;
      align-items: center;
      .MuiSvgIcon-root {
        fill: currentColor;
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 1.5rem;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
        vertical-align: middle;
        // margin-top: 15px;
      }
      .product_min_btn {
        color: black;
        height: 45px;
        flex: 1;
        // margin-right: -2px;
        // padding-left: 16px;
        border-radius: 50px;
        // padding-right: 16px;
        margin-left: 20px;
        // margin-top: -1px;
        margin-right: -30px;
        background-color: #e9b10e;
        font-size: 14px;
        min-width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .details_count_box {
      max-width: 400px;
      // padding: 20px 40px;
      // background: rgba($color: #ffffff26, $alpha: 0.15);
      border-radius: 4px;
      margin: 20px 0;
      .details_count_title {
        margin: 0 auto 20px;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
        
      }
      .details_count {
        color: #fff;
        display: inline-block;
        // padding: 6.4px 16px;
        width: 48px;
        height: 48px;
        background: #333542;
        font-size: 18px;
        margin: 5px;
        text-align: center;
        line-height: 48px;
        border-radius: 50%;
        cursor: pointer;
        margin: 0 10px;
        &:hover {
          background: #444569;
        }
      }
      .count_light {
        background: #17b4ed;
        box-shadow: 0 0 15px #17b4ed;
        &:hover {
          background: #17b4ed;
        }
      }
    }
  }
}
</style>